<template>
  <div class="vap-page">
    <Loading
      class="vasion-loading-indicator"
      :active.sync="isLoading"
      :is-full-page="false"
      :color="loaderColor"
      loader="dots"
      :background-color="loaderBackgroundColor"
    />
    <div class="vap-page-header">
      <h1 class="">All {{ $formsLabelPlural }}</h1>
      <div class="btns-container">
        <VasionButton v-if="attributeFormsAvailable" id="new-attribute-form-header-button" classProp="primary"
          class="last-btn"
          @vasionButtonClicked="newFormClick">
          New {{ $formsLabel }}
        </VasionButton>
      </div>
    </div>
    <div class="vap-page-content">
      <div v-if="loadingForms === true">
        <!-- If this is not here, the no-forms-div flashes for a second while the form list loads - not great -->
      </div>
      <div v-else-if="attributeFormsAvailable" class="main-table-container vasion-html-table-default">
        <VasionTable
          :headerColumns="tableData.Columns.Values"
          :tableRows="tableData.Rows.Values"
          :hideColumns="hiddenColumns"
          :ellipsisButtonConfig="ellipsisButtonConfig"
          @ellipsis-button-clicked="ellipsisButtonClicked"
          @vasion-selection="handleRowSelect"
        />
      </div>
      <div v-else class="no-forms-div">
        <VasionEmptyForm />
        <div class="vasion-large-message">
          {{ greetingMessage }}
        </div>
        <div class="vasion-page-subheader instructional-message-div">
          It feels a little empty, create {{ $formsLabelWithPrefix }} to get started!
        </div>
        <div class="button-div">
          <VasionButton
            id="new-form-button"
            :isFullWidth="true"
            :classProp="'primary'"
            @vasionButtonClicked="newFormClick"
          >
            <span class="vasion-button-plus">+</span>New {{ $formsLabel }}
          </VasionButton>
        </div>
      </div>
    </div>
    <VasionGeneralModal
      id="confirmFormDelete"
      :rejectButtonText="'No'"
      :confirmButtonText="'Yes'"
      :modalType="'message'"
      buttonGroup="center"
      :message="deletePromptMessage"
      :sync="showDeleteDialog"
      @noButtonClick="toggleDeleteDialog()"
      @confirmButtonClick="deleteFormAndToggle()"
    />
    <VasionSnackbar
      id="all-attribute-forms-snackbar"
      :show-snackbar-bool.sync="showSnackbarBool"
      :snackbar-image="snackbarImage"
      :snackbar-sub-title="snackbarSubTitle"
      :snackbar-title="snackbarTitle"
    />
  </div>
</template>

<script>
import Loading from 'vue-loading-overlay';
import 'vue-loading-overlay/dist/vue-loading.css';
import { loaderBackgroundColor, loaderColor } from '@/assets/js/styleConfig'

export default {
  name: 'AdminAllAttributeForms',
  components: {
    Loading,
  },
  data: function () {
    return {
      columns: {
        Values: [],
        ValueType: 'String',
      },
      deleteFormID: 0,
      deleteFormName: '',
      ellipsisButtonConfig: { buttons: [] },
      greetingMessage: 'Hello!',
      headerCheckboxToggled: false,
      hiddenColumns: ['_FormID_'],
      isLoading: false,
      loaderBackgroundColor,
      loaderColor,
      loadingForms: true,
      showDeleteDialog: false,
      showSnackbarBool: false,
      snackbarImage: false,
      snackbarSubTitle: '',
      snackbarTitle: '',
      tableData: {},
    }
  },
  computed: {
    attributeFormsAvailable() { return this.attributeFormsList && this.attributeFormsList.length > 0 },
    attributeFormsList() { return this.$store.state.attributeForm.forms },
    deletePromptMessage() { return `Are you sure you want to delete "${this.deleteFormName}"?` },
  },
  watch: {
    attributeFormsList: function () { this.buildTableData() },
  },
  async created() {
    this.columns.Values = [
      '_FormID_',
      '_VasionIcon_',
      `${$formsLabel} Name`,
      '_VasionEllipsisButton_',
    ]
    this.ellipsisButtonConfig.buttons = [
      {
        id: 'edit-form-button',
        name: `Edit ${$formsLabel}`,
        icon: 'VasionEditIcon',
      },
      {
        id: 'delete-form-button',
        name: `Delete ${$formsLabel}`,
        icon: 'VasionDeleteIcon',
      },
    ]

    const promises = await Promise.all([
      this.$store.dispatch('attributeForm/getForms'),
      this.$store.dispatch('common/getEmptyAppGreeting'),
    ])
    // eslint-disable-next-line prefer-destructuring
    this.greetingMessage = promises[1]
    this.loadingForms = false
  },
  methods: {
    async buildTableData() {
      const data = {
        dataList: this.attributeFormsList,
        columns: this.columns,
      }

      this.tableData = await this.$store.dispatch('attributeForm/setFormTableData', data)
    },
    async deleteFormAndToggle() {
      this.toggleDeleteDialog()
      if (this.deleteFormID <= 0) {
        return
      }

      this.isLoading = true

      const deleteResult = await this.$store.dispatch('attributeForm/deleteAttributeForm', this.deleteFormID)
      if (deleteResult === '') {
        this.snackbarTitle = 'Success!'
        this.snackbarSubTitle = `The ${$formsLabel} was Deleted!`
        this.snackbarImage = true
      } else {
        this.snackbarTitle = 'Failure'
        this.snackbarSubTitle = deleteResult
        this.snackbarImage = false
      }

      this.deleteFormID = 0
      this.deleteFormName = ''
      this.isLoading = false
      this.showSnackbarBool = true
    },
    deleteFormClick(formID, formName) {
      this.deleteFormID = formID
      this.deleteFormName = formName
      this.toggleDeleteDialog()
    },
    ellipsisButtonClicked(payload) {
      if (payload?.item?.Values[0]) {
        const formID = payload.item.Values[0]
        const form = this.attributeFormsList.find(f => f.value === formID)

        switch (payload.buttonId) {
          case 'edit-form-button':
            this.editForm(formID)
            break;
          case 'delete-form-button':
            this.deleteFormClick(form.value, form.name)
            break;
          default:
            break;
        }
      }
    },
    async editForm(formID) { this.$router.push({ name: 'EditForm', params: { formID: formID } }) },
    handleRowSelect(selectInfo) {
      if (!selectInfo.Values || selectInfo.Values.length <= 1 || selectInfo.Values[0] <= 0) {
        return
      }

      // eslint-disable-next-line
      this.editForm(selectInfo.Values[0])
    },
    newFormClick() {
      this.$store.dispatch('forms/clearEform')
      this.$router.push({ name: 'NewForm' })
    },
    toggleDeleteDialog() { this.showDeleteDialog = !this.showDeleteDialog },
  },
}
</script>

<style lang="scss" scoped>
.button-div {
  margin: auto;
  margin-top: 30px;
  width: 288px;
}

.instructional-message-div {
  margin-top: 24px;
}

.no-forms-div {
  margin-top: 60px;
}

.grid-div {
  max-height: calc(100vh - 186px);
  overflow: auto;
  text-align: left;
}
</style>
